"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Button = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/action/Button"));
var _Radio = require("@oberoninternal/travelbase-ds/components/form/Radio");
var _ModalHeader = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/ModalHeader"));
var _grid = require("@rebass/grid");
var _formik = require("formik");
var _react = _interopRequireWildcard(require("react"));
var _reactIntl = require("react-intl");
var _Stack = _interopRequireDefault(require("./Stack"));
var _searchOrderMessages = _interopRequireDefault(require("../constants/searchOrderMessages"));
var _Modal = _interopRequireDefault(require("@oberoninternal/travelbase-ds/components/layout/Modal"));
var _useEmbedEvents = require("../hooks/useEmbedEvents");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function (e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != typeof e && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var __jsx = _react.default.createElement;
const SearchSortModal = props => {
  const {
    submitForm
  } = (0, _formik.useFormikContext)();
  const sendEvent = (0, _useEmbedEvents.useSendEvent)();
  (0, _react.useEffect)(() => {
    sendEvent({
      type: 'iframeFullScreenToggle',
      fullscreen: props.open,
      source: 'travelbase'
    });
  }, [props.open, sendEvent]);
  return __jsx(_Modal.default, props, __jsx(_ModalHeader.default, {
    style: {
      textTransform: 'capitalize'
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "Y1jaCK",
    defaultMessage: [{
      "type": 0,
      "value": "sorteren"
    }]
  })), __jsx(_Stack.default, {
    my: 6,
    spacing: 4
  }, Object.entries(props.searchOrder).map(([key, value]) => __jsx(_grid.Flex, {
    key: key,
    alignItems: "center"
  }, __jsx(_Radio.RadioField, {
    name: "order",
    id: value
  }), __jsx("label", {
    htmlFor: value
  }, __jsx(_reactIntl.FormattedMessage, _searchOrderMessages.default[key]))))), __jsx(_Button.default, {
    style: {
      width: '100%'
    },
    onClick: () => {
      props.onClose();
      setTimeout(() => {
        /* As the modal is rendered through a portal, the form won't be automatically submitted */
        submitForm();
      });
    }
  }, __jsx(_reactIntl.FormattedMessage, {
    id: "QNDRr3",
    defaultMessage: [{
      "type": 0,
      "value": "Sortering toepassen"
    }]
  })));
};
var _default = exports.default = SearchSortModal;