"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _react = _interopRequireDefault(require("react"));
var __jsx = _react.default.createElement;
const SearchIcon = props => __jsx("svg", (0, _extends2.default)({
  viewBox: "0 0 22 22",
  xmlns: "http://www.w3.org/2000/svg"
}, props), __jsx("g", {
  transform: "translate(1.6 1.1)",
  stroke: "currentcolor",
  strokeWidth: "2",
  fill: "none",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round"
}, __jsx("circle", {
  transform: "rotate(-23.025 9.13 9.13)",
  cx: "9.13",
  cy: "9.13",
  r: "6.522"
}), __jsx("path", {
  d: "M15.795 15.795l3.478 3.479"
})));
var _default = exports.default = SearchIcon;